<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <v-toolbar flat dark color="primary">
            <v-toolbar-title>{{
              $t("contactus") | capitalize
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-select
                v-model="subject"
                :rules="[(v) => !!v || $t('contact.requiredsubject')]"
                :items="subjects"
                :label="$t('contact.selectsubject') | capitalize"
                prepend-icon="mdi-help-circle"
                single-line
                required
                return-object
              >
                <template v-slot:selection="{ item }">{{
                  $t("contact." + item.text)
                }}</template>
                <template v-slot:item="{ item }">{{
                  $t("contact." + item.text)
                }}</template>
              </v-select>
              <!-- <v-file-input
                v-model="files"
                multiple
                :label="$t('contact.attachfile') | capitalize"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input> -->
              <v-textarea
                v-model="message"
                :rules="[(v) => !!v || $t('contact.requiredmessage')]"
                :label="$t('contact.yourmessage') | capitalize"
                rows="6"
                prepend-icon="mdi-comment"
                clearable
                clear-icon="mdi-close-circle"
                required
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="!valid"
              @click="sendMessage(subject.value, message)"
              color="primary"
              >{{ $t("contact.send") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      valid: true,
      subject: null,
      subjects: [
        { text: "information", value: "INFORMATION" },
        { text: "bug", value: "BUG" },
        // { text: "fraud", value: "FRAUD" },
        { text: "improvmentrequest", value: "IMPROVMENT_REQUEST" },
      ],
      //files: [],
      message: "",
    };
  },
  methods: {
    sendMessage(subject, message) {
      this.$store.dispatch("contact/sendMessage", {
        subject,
        message,
      });
    },
  },
};
</script>
